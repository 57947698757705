import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import cx from 'classnames';
import AppStore from '../../store/AppStore';

import './styles.scss';

export default class PageOverlay extends Component {
  constructor() {
    super();

    const defaultState = {
      pageTransitionFinished: true,
      pageOutTransition: true,
      pageInTransition: true,
      toggleElement: false,
      pageTransition: false,
    };

    this.state = Object.assign(defaultState, AppStore.getConfig());
    this.fluxStateChange = this.fluxStateChange.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    AppStore.on('change', this.fluxStateChange);
  }

  componentWillUnmount() {
    AppStore.removeListener('change', this.fluxStateChange);
  }

  fluxStateChange() {
    this.setState(AppStore.getConfig());
  }

  render() {
    const { pageTransition, colors } = this.state;

    return (
      <ReactCSSTransitionGroup
        transitionName="pageTransition"
        transitionEnterTimeout={700}
        transitionLeaveTimeout={700}
      >
        {pageTransition ? (
          <div
            className={cx({
              'page-overlay': true,
              //   'from-bottom': true
              // 'from-bottom': this.state.pageInTransition,
              // 'from-bottom-end': this.state.pageOutTransition
            })}
          >
            <div
              className="animate-box-bottom"
              style={{ backgroundColor: colors.highlight }}
            />
          </div>
        ) : null}
      </ReactCSSTransitionGroup>
    );
  }
}
