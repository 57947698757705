/* eslint-disable no-bitwise */
import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/Dispatcher';

EventEmitter.prototype._maxListeners = 100;

class AppStore extends EventEmitter {
  constructor() {
    super();

    this.config = {
      colors: {
        highlight: '#FF00B2',
        background: '#222122',
      },
      images: {},
      pageTransition: false,
      showMenu: false,
      location: {},
      pageSpecs: {},
    };
  }

  changeHighlightColor(color) {
    this.config.colors.highlight = color;
    this.emit('change');
  }

  changeBackgroundColor(color) {
    this.config.colors.background = color;
    this.emit('change');
  }

  setLocation(loc) {
    this.config.location = loc;
    this.emit('change');
  }

  changeColors(colors) {
    this.config.colors = colors;
    this.emit('change');
  }

  showMenu() {
    this.config.showMenu = true;
    this.emit('change');
  }

  hideMenu() {
    this.config.showMenu = false;
    this.emit('change');
  }

  storeImages(images) {
    this.config.images = images;
    this.emit('change');
  }

  setPageSpecs(pageSpecs) {
    this.config.pageSpecs = pageSpecs;
    this.emit('change');
  }

  endPageTransition() {
    this.config.pageTransition = false;
    this.emit('change');
  }

  startPageTransition() {
    this.config.pageTransition = true;
    this.emit('change');
  }

  getImages() {
    return this.config.images;
  }

  getConfig() {
    return this.config;
  }

  toggleLightContent() {
    this.config.pageSpecs.lightContent = !this.config.pageSpecs.lightContent;
    this.emit('change');
  }

  setRandomColor() {
    this.config.colors.background = `#${(
      (Math.random() * 0xffffff) <<
      0
    ).toString(16)}`;
    this.config.colors.highlight = `#${(
      (Math.random() * 0xffffff) <<
      0
    ).toString(16)}`;
    this.emit('change');
  }

  handleActions(action) {
    switch (action.type) {
      case 'STORE_IMAGES': {
        this.storeImages(action.images);
        break;
      }

      case 'SET_LOCATION': {
        this.setLocation(action.loc);
        break;
      }
      case 'SHOW_MENU': {
        this.showMenu();
        break;
      }
      case 'HIDE_MENU': {
        this.hideMenu();
        break;
      }

      case 'GET_IMAGES': {
        this.getImages();
        break;
      }

      case 'CHANGE_HIGHLIGHTCOLOR': {
        this.changeHighlightColor(action.color);
        break;
      }

      case 'CHANGE_BACKGROUNDCOLOR': {
        this.changeBackgroundColor(action.color);
        break;
      }

      case 'CHANGE_COLORS': {
        this.changeColors(action.colors);
        break;
      }
      case 'END_PAGE_TRANSITION': {
        this.endPageTransition();
        break;
      }
      case 'START_PAGE_TRANSITION': {
        this.startPageTransition();
        break;
      }
      case 'SET_PAGESPECS': {
        this.setPageSpecs(action.pageSpecs);
        break;
      }
      default: {
        // noop
      }
    }
  }
}

const appStore = new AppStore();

AppDispatcher.register(appStore.handleActions.bind(appStore));

if (typeof window !== 'undefined') {
  window.appStore = appStore;
}
export default appStore;
