import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import LayoutWithData from './LayoutWithData';

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        images: allImageSharp {
          edges {
            node {
              sizes(maxWidth: 2400) {
                originalName
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 250)
              id
              frontmatter {
                path
                title
                subtitle
                lightContent
                heroImage
                tags
              }
            }
          }
        }
        site {
          siteMetadata {
            index {
              title
              subtitle
              description
              heroImage
              lightContent
              colors {
                highlight
                background
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        {/* No location needed, get location with in LayoutWithData from AppStore  */}
        <LayoutWithData location={location} data={data}>
          {children}
        </LayoutWithData>
      </>
    )}
  />
);

export default Layout;

Layout.propTypes = {
  children: PropTypes.any,
};
