import React, { Component } from 'react';
import cx from 'classnames';
import * as AppActions from '../../actions/AppActions';

import LinkPageTransition from '../LinkPageTransition';
import AppStore from '../../store/AppStore';

import './styles.scss';

const navigationItems = [
  {
    name: 'Portfolio',
    path: '/',
  },
  {
    name: 'Me',
    path: '/me/',
  },
  {
    name: 'Impressum',
    path: '/impressum/',
  },
  {
    name: 'Datenschutz',
    path: '/datenschutz/',
  },
];

export default class Menu extends Component {
  constructor() {
    super();
    this.state = { ...AppStore.getConfig() };

    this.hideMenu = this.hideMenu.bind(this);
    this.fluxStateChange = this.fluxStateChange.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    AppStore.on('change', this.fluxStateChange);
  }

  componentWillUnmount() {
    AppStore.removeListener('change', this.fluxStateChange);
  }

  hideMenu = () => {
    AppActions.hideMenu();
    AppActions.startPageTransition();
    setTimeout(() => {
      AppActions.endPageTransition();
    }, 300);
  };

  fluxStateChange() {
    this.setState(AppStore.getConfig());
  }

  render() {
    const { showMenu } = this.state;
    return (
      <div
        id="menu-overlay"
        className={cx({
          active: showMenu,
        })}
      >
        <div className="outer">
          <div className="inner">
            <div
              id="close-menu"
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  this.hideMenu();
                }
              }}
              onClick={() => {
                this.hideMenu();
              }}
              role="button"
              tabIndex="0"
              aria-label="close"
            />

            <nav>
              <ul className="main-menu">
                {/* TODO: set active class and styling correct */}
                {navigationItems.map((navigationItem, index) => {
                  const isExternal = navigationItem.path.startsWith('http');
                  return (
                    <li key={`menu-navigation-item-${index}`}>
                      {isExternal && (
                        <a
                          className="menu-link"
                          href={navigationItem.path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span />
                          {navigationItem.name}
                        </a>
                      )}
                      {!isExternal && (
                        <LinkPageTransition
                          to={navigationItem.path}
                          className="ajax-link menu-link"
                          data-type="page-transition"
                          activeClassName="active"
                          isMenuLink
                        >
                          <span />
                          {navigationItem.name}
                        </LinkPageTransition>
                      )}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
