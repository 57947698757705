import React, { Component } from 'react';
import cx from 'classnames';
// import Svg from 'react-svg-inline';
import LinkPageTransition from '../LinkPageTransition';

import * as AppActions from '../../actions/AppActions';
import AppStore from '../../store/AppStore';

import { ReactComponent as Logo } from './images/logo.svg';
// import './styles.scss';

class Header extends Component {
  constructor() {
    super();
    this.state = { ...AppStore.getConfig() };

    this.startPageTransition = this.startPageTransition.bind(this);
    this.fluxStateChange = this.fluxStateChange.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    AppStore.on('change', this.fluxStateChange);
  }

  componentWillUnmount() {
    AppStore.removeListener('change', this.fluxStateChange);
  }

  startPageTransition = () => {
    AppActions.startPageTransition();
  };

  toggleMenu = () => {
    const { showMenu } = this.state;
    if (showMenu) {
      AppActions.hideMenu();
    } else {
      AppActions.showMenu();
    }

    AppActions.startPageTransition();
    setTimeout(() => {
      AppActions.endPageTransition();
    }, 300);
  };

  fluxStateChange() {
    this.setState(AppStore.getConfig());
  }

  render() {
    const { showMenu } = this.state;
    return (
      <header
        className={cx({
          'scroll-hide': true,
          open: showMenu,
        })}
      >
        <div id="header-container">
          <div id="logo">
            <LinkPageTransition
              to="/"
              className="ajax-link"
              data-type="page-transition"
              isLogo
            >
              {/* <Svg svg={logo} className="color-logo" cleanup /> */}
              <Logo className="color-logo" />
            </LinkPageTransition>
          </div>

          <div
            role="button"
            tabIndex={0}
            id="menu-burger"
            className={cx({
              open: showMenu,
            })}
            onClick={() => {
              this.toggleMenu();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.toggleMenu();
              }
            }}
          >
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </header>
    );
  }
}

// const Header = ({ title }) => (

// );

export default Header;
