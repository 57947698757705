import React, { Component } from 'react';
import Link from 'gatsby-link';

import * as AppActions from '../../actions/AppActions';

// import './styles.scss';

class BackButton extends Component {
  constructor() {
    super();
    this.startPageTransition = this.startPageTransition.bind(this);
  }

  startPageTransition = () => {
    AppActions.startPageTransition();
  };

  render() {
    return (
      <Link
        to="/"
        id="page-action-holder"
        className="ajax-link"
        data-type="page-transition"
        data-tooltip="Back to Home"
        data-placement="top"
        onClick={this.startPageTransition}
      >
        <div id="backtoworks">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="50px"
            height="50px"
            viewBox="0 0 80 80"
            xmlSpace="preserve"
          >
            <circle className="circle-action is-inner" cx="40" cy="40" r="36" />
            <circle className="circle-action is-outer" cx="40" cy="40" r="36" />
          </svg>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
      </Link>
    );
  }
}

// const BackButton = ({}) => (
//   <Link
//     to="/"
//     id="page-action-holder"
//     className="ajax-link"
//     data-type="page-transition"
//     data-tooltip="Back to Home"
//     data-placement="top"
//   >
//     <div id="backtoworks">
//       <svg
//         version="1.1"
//         xmlns="http://www.w3.org/2000/svg"
//         x="0px"
//         y="0px"
//         width="50px"
//         height="50px"
//         viewBox="0 0 80 80"
//         xmlSpace="preserve"
//       >
//         <circle className="circle-action is-inner" cx="40" cy="40" r="36" />
//         <circle className="circle-action is-outer" cx="40" cy="40" r="36" />
//       </svg>
//       <i className="fa fa-times" aria-hidden="true" />
//     </div>
//   </Link>
// );

export default BackButton;
