import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';

const Filter = ({ posts }) => {
  const tags = uniq(
    flattenDeep(posts.map((post) => get(post, 'node.frontmatter.tags')))
  );
  return (
    <div className="page-action-overlay">
      <div className="outer">
        <div className="inner">
          <div className="close-page-action" />
          <ul id="filters">
            <li>
              <div role="button" id="all" data-filter="*" className="active">
                <span />
                All
              </div>
            </li>
            {tags.map((tag, index) => (
              <li key={`filter-tag-${index}`}>
                <div role="button" data-filter={`.${tag}`}>
                  <span />
                  {tag}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Filter;

Filter.propTypes = {
  posts: PropTypes.array,
};
