import React from 'react';
import LinkPageTransition from '../LinkPageTransition';
// import './styles.scss';

// keep it short
const navigationItems = [
  {
    name: 'Impressum',
    path: '/impressum/',
  },
  {
    name: 'Datenschutz',
    path: '/datenschutz/',
  },
];
const FooterNavigation = () => (
  <div id="socials-holder">
    <ul className="socials-text">
      {/* TODO: better name maybe? */}
      {navigationItems.map((navigationItem, index) => {
        const isExternal = navigationItem.path.startsWith('http');
        return (
          <li key={`footer-navigation-item-${index}`}>
            {isExternal && (
              <a href={navigationItem.path} target="_blank" rel="noreferrer">
                {navigationItem.name}
              </a>
            )}

            {!isExternal && (
              <LinkPageTransition to={navigationItem.path}>
                {navigationItem.name}
              </LinkPageTransition>
            )}
          </li>
        );
      })}
    </ul>
  </div>
);

export default FooterNavigation;
