import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';
// import { Redirect } from 'react-router';
// import { navigateTo } from 'gatsby-link';
import { navigate } from 'gatsby-link';
import AppStore from '../store/AppStore';

import * as AppActions from '../actions/AppActions';

const LinkPageTransition = ({ children, to, isMenuLink, ...props }) => {
  const [gotClicked, setGotClicked] = useState(false);

  useEffect(() => {
    const clickHandler = (target) => {
      // for navigation links, the logo should be hidden after navigating
      if (isMenuLink) {
        AppActions.hideMenu();
      }

      navigate(target);
    };

    let timeout;

    if (gotClicked) {
      AppActions.startPageTransition();
      timeout = setTimeout(() => {
        if (AppStore.config.location.pathname === to) {
          AppActions.endPageTransition();
          setGotClicked(false);
        } else {
          clickHandler(to);
        }
      }, 600);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [gotClicked, to, isMenuLink]);

  return (
    <a
      role="button"
      tabIndex="-1"
      onKeyDown={() => {
        // TODO do something
      }}
      onClick={(e) => {
        e.preventDefault();
        setGotClicked(true);
      }}
      {...props}
    >
      {children}
    </a>
  );
};

export default LinkPageTransition;

// class LinkPageTransition extends React.Component {
//   constructor() {
//     super();
//     this.state = { link: false };
//     this.onClick = this.onClick.bind(this);
//     this.toggle = this.toggle.bind(this);
//     this.reveal = this.reveal.bind(this);
//   }

//   onClick(e) {
//     e.preventDefault();
//     AppActions.startPageTransition();
//     const { setTimeout } = this.props;
//     setTimeout(this.toggle, 600);
//   }

//   toggle() {
//     const { isLogo, setTimeout, to, isMenuLink } = this.props;

//     if (isMenuLink) {
//       AppActions.hideMenu();
//     }

//     this.setState({ link: true }, function () {
//       if (isLogo) {
//         setTimeout(this.reveal, 50);
//       }
//     });

//     if (AppStore.config.location.pathname === to) {
//       AppActions.hideMenu();
//       AppActions.endPageTransition();
//     } else {
//       // navigateTo(to);
//       navigate(to);
//     }
//   }

//   reveal() {
//     this.setState({ link: false });
//   }

//   render() {
//     const { className, to, children } = this.props;
//     return (
//       <div className="linkpagetransition">
//         {/* {this.state.link ? <Redirect push to={this.props.to} /> : null} */}
//         <a onClick={this.onClick} className={className} href={to}>
//           {children}
//         </a>
//       </div>
//     );
//   }
// }

LinkPageTransition.propTypes = {
  isLogo: PropTypes.bool,
  isMenuLink: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
  setTimeout: PropTypes.func,
};

LinkPageTransition.defaultProps = {
  isLogo: false,
  isMenuLink: false,
  className: '',
};
// export default ReactTimeout(LinkPageTransition);
